import { ClassType } from '@shared/types';
import { Expose, Type } from 'class-transformer';
import { IsArray, IsBoolean, IsDate, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { cloneDeep, isArray } from 'lodash';

export interface FieldsTarget {
  fields?: Record<string, FieldOptions>;
}

export interface FieldOptions {
  required: boolean;
  type: ClassType;
}

export function Field(
  required: boolean,
  fieldType: ClassType | [ClassType],
  validators: PropertyDecorator[] = []
): PropertyDecorator {
  return (target: FieldsTarget, propertyKey: string | symbol) => {
    propertyKey = propertyKey.toString();
    const type: ClassType = isArray(fieldType) ? fieldType[0] : fieldType;
    const typeValidator = getTypeValidator(type, isArray(fieldType));

    Expose()(target, propertyKey);

    if (type.name !== Object.name) {
      Type(() => type)(target, propertyKey);
    }

    if (required) {
      validators.push(IsNotEmpty());
    } else {
      validators.push(IsOptional());
    }

    if (isArray(fieldType)) {
      validators.push(IsArray());
    }

    if (typeValidator) {
      validators.push(typeValidator);
    }

    for (const validator of validators) {
      validator(target, propertyKey);
    }

    const fields: Record<string, FieldOptions> = cloneDeep(target.fields ?? {});
    fields[propertyKey] = { required, type };
    target.fields = fields;
  };
}

export function getTypeValidator(type: ClassType, isArray: boolean): PropertyDecorator | undefined {
  const options = { each: isArray };

  if (type.name === Boolean.name) {
    return IsBoolean(options);
  } else if (type.name === Number.name) {
    return IsNumber({}, options);
  } else if (type.name === String.name) {
    return IsString(options);
  } else if (type.name === Date.name) {
    return IsDate(options);
  }

  return;
}
