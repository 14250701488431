import { Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { ENVIRONMENT, SharedEnv } from 'shared-ui/di/environment';

@Injectable()
export class AppLogger {
  constructor(@Inject(ENVIRONMENT) private env: SharedEnv) {}

  logUserAction(action: string, category = 'click') {
    if (this.env.sentry.enabled) {
      Sentry.addBreadcrumb({
        category,
        level: 'info',
        message: action,
        type: 'user',
      });
    } else {
      console.log(AppLogger.name, category, action);
    }
  }
}
