import { IsEntity } from '@shared/entity/is-entity';
import { Field } from '@shared/model/field';
import { ClassType, ObjectType } from '@shared/types';
import { IsArray } from 'class-validator';

export function EntityField(required: boolean, type: ClassType): PropertyDecorator {
  return (target: ObjectType, propertyKey: string | symbol) => {
    propertyKey = propertyKey.toString();
    Field(required, type, [IsEntity(type)])(target, propertyKey);
  };
}

export function ArrayEntityField(required: boolean, type: ClassType): PropertyDecorator {
  return (target: ObjectType, propertyKey: string | symbol) => {
    propertyKey = propertyKey.toString();
    Field(required, type, [IsArray(), IsEntity(type, { each: true })])(target, propertyKey);
  };
}
