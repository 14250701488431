import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isFunction } from 'lodash';
import { Observable } from 'rxjs';
import { Params, Translator } from 'shared-ui/providers/translator';

export type TypedForm<T> = FormGroup<{ [K in keyof Required<T>]: FormControl<T[K] | undefined> }>;
export type FormlyFieldProps = FormlyFieldConfig['props'];

@Injectable({ providedIn: 'root' })
export class FormlyBuilder {
  readonly defaultOptions: FormlyFieldProps = { appearance: 'outline', floatLabel: 'always' };

  constructor(private translator: Translator) {}

  createForm<T>(controls?: { [K in keyof Required<T>]: AbstractControl }): TypedForm<T> {
    return new FormGroup(controls ?? {}) as FormGroup;
  }

  getFormValue<T>(form: FormGroup): T {
    return form.getRawValue();
  }

  processForm<T>(form: FormGroup): Observable<T> {
    return new Observable(subscriber => {
      if (form.valid) {
        subscriber.next(form.getRawValue());
      }
      subscriber.complete();
    });
  }

  create(fields: FormlyFieldConfig[]) {
    fields.forEach(field => {
      this.setField(field);
    });
    return fields;
  }

  getConfirmOptions() {
    return [
      { value: true, label: this.translate('Yes') },
      { value: false, label: this.translate('No') },
    ];
  }

  translate(message: string, params?: Params) {
    return this.translator.translate(message, params);
  }

  createSameValidator(form: FormGroup, controlName: string, message: string) {
    return {
      expression: (control: FormControl) => {
        return form.value[controlName] === control.value;
      },
      message: this.translator.translate(message),
    };
  }

  private setField(field: FormlyFieldConfig) {
    const specialTypes = ['checkbox', 'radio', 'slider', 'toggle'];
    const fieldProps = Object.assign({}, this.defaultOptions, field.props);
    if (specialTypes.includes(field.type as string)) {
      field.wrappers = [];
    }
    if (fieldProps.label) {
      fieldProps.label = this.translate(fieldProps.label);
    }
    if (fieldProps.placeholder) {
      fieldProps.placeholder = this.translate(fieldProps.placeholder);
    }
    if (fieldProps.description) {
      fieldProps.description = this.translate(fieldProps.description);
    }
    if (field.fieldGroup) {
      field.fieldGroup.forEach(child => this.setField(child));
    }
    if (field.fieldArray && !isFunction(field.fieldArray)) {
      this.setField(field.fieldArray);
    }
    field.props = fieldProps;
  }
}
