import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Translator } from 'shared-ui/providers/translator';

@Injectable({
  providedIn: 'root',
})
export class FlashMessages {
  defaultOptions: MatSnackBarConfig = {
    duration: 5000,
    panelClass: 'info',
    verticalPosition: 'top',
  };

  constructor(
    private flashesFactory: MatSnackBar,
    private translator: Translator
  ) {}

  get factory() {
    return this.flashesFactory;
  }

  show(
    message: string,
    action?: string,
    config?: MatSnackBarConfig,
    translate = true
  ) {
    if (translate) message = this.translator.translate(message);
    config = Object.assign(this.defaultOptions, config);
    return this.flashesFactory.open(message, action, config);
  }

  showFromComponent<T>(
    component: ComponentType<T>,
    config?: MatSnackBarConfig
  ) {
    config = Object.assign(this.defaultOptions, config);
    return this.flashesFactory.openFromComponent(component, config);
  }

  showFromTemplate<T>(template: TemplateRef<T>, config?: MatSnackBarConfig) {
    config = Object.assign(this.defaultOptions, config);
    return this.flashesFactory.openFromTemplate(template, config);
  }
}
