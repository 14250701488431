import { BaseRootEntity } from '@shared/entity/base-root-entity';
import { Field } from '@shared/model/field';

export class ArticleEntity extends BaseRootEntity {
  @Field(true, String)
  title!: string;

  @Field(true, String)
  category!: string;

  @Field(true, String)
  url!: string;

  @Field(true, Number)
  position = 1;

  @Field(true, String)
  content!: string;
}
