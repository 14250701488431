import { isPlatformBrowser } from '@angular/common';
import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProgressService {
  private $navigation = new EventEmitter<boolean>();

  private $pageLoad = new EventEmitter<boolean>();

  private $menuToggle = new BehaviorSubject<boolean>(false);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  get navigation() {
    return this.$navigation;
  }

  get pageLoad() {
    return this.$pageLoad;
  }

  get menuToggle() {
    return this.$menuToggle;
  }

  navigationStart() {
    this.$navigation.emit(true);
  }

  navigationEnd() {
    this.$navigation.emit(false);
  }

  pageLoadStart() {
    this.$pageLoad.emit(true);
  }

  pageLoadEnd() {
    this.$pageLoad.emit(false);
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }
}
