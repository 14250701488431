import { Injectable } from '@angular/core';
import { ArticleCategories } from '@shared/domain/articles/article-categories';
import { ArticleEntity } from '@shared/domain/articles/article.entity';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SelectOption } from 'shared-ui/models/select-option';
import { CrudHttpService } from 'shared-ui/providers/crud-http-service';

export interface ArticlesCategory {
  url: string;
  label: string;
  articles: ArticleEntity[];
}

@Injectable({ providedIn: 'root' })
export class ArticlesService extends CrudHttpService<ArticleEntity> {
  protected baseUrl = 'articles';
  protected entityClass = ArticleEntity;
  protected articles?: ArticleEntity[];
  protected articlesLoad$?: Observable<ArticleEntity[]>;

  getCategoriesOptions() {
    const options: SelectOption[] = Object.entries(ArticleCategories).map(item => {
      return { label: item[1], value: item[0] };
    });
    return options;
  }

  load() {
    if (this.articles) {
      return of(this.articles);
    }

    if (!this.articlesLoad$) {
      this.articlesLoad$ = this.find().pipe(
        shareReplay(1),
        map(articles => (this.articles = articles.items))
      );
    }

    return this.articlesLoad$;
  }

  getArticlesCategories(articles: ArticleEntity[]): ArticlesCategory[] {
    return Object.entries(ArticleCategories).map(([key, label]) => {
      return {
        url: key,
        label,
        articles: articles.filter(item => item.category === key),
      };
    });
  }
}
