import { HttpClient, HttpErrorResponse, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { ErrorHandler, Injectable, Type } from '@angular/core';
import { UpdateManyResult } from '@shared/results/update-many.result';
import { Dictionary } from '@shared/types';
import { plainToInstance } from 'class-transformer';
import { isNotEmpty } from 'class-validator';
import { isNumber, isString, set } from 'lodash';
import { DateTime } from 'luxon';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { Find, FindFilter } from 'shared-ui/models/find';
import { MESSAGES } from 'shared-ui/models/messages';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { Translator } from 'shared-ui/providers/translator';

export type HttpFilter = {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
};

@Injectable()
export abstract class CrudHttpService<T> {
  protected readonly DELAY = 250;
  protected abstract baseUrl: string;
  protected entityClass?: Type<T>;

  constructor(
    protected errorHandler: ErrorHandler,
    protected flash: FlashMessages,
    protected http: HttpClient,
    protected translator: Translator
  ) {}

  find(find: Find = {}): Observable<{ items: T[]; total: number }> {
    const query = this.buildQuey(find);
    return this.http.get<{ items: T[]; total: number }>(`${this.baseUrl}?${query}`).pipe(
      map(res => {
        const items = this.entityClass ? plainToInstance(this.entityClass, res.items) : res.items;
        return { items, total: res.total };
      }),
      catchError(error => this.handleQueryError(error, { items: [], total: 0 }))
    );
  }

  get(id: string): Observable<T | undefined> {
    return this.http.get<T>(`${this.baseUrl}/${id}`).pipe(
      map(item => (this.entityClass ? plainToInstance(this.entityClass, item) : item)),
      catchError(error => this.handleQueryError(error, undefined))
    );
  }

  create(body: Partial<T>): Observable<T | null> {
    this.sanitizeValues(body);
    return this.http.post<T>(`${this.baseUrl}`, body).pipe(
      delay(this.DELAY),
      map(item => (this.entityClass ? plainToInstance(this.entityClass, item) : item)),
      catchError(error => this.handleCommandError(error, null))
    );
  }

  createFromData(id: string, data: Dictionary): Observable<T | null> {
    return this.create(data as Partial<T>);
  }

  update(id: string, body: Partial<T>): Observable<T | null> {
    this.sanitizeValues(body);
    return this.http.put<T>(`${this.baseUrl}/${id}`, body).pipe(
      delay(this.DELAY),
      map(item => (this.entityClass ? plainToInstance(this.entityClass, item) : item)),
      catchError(error => this.handleCommandError(error, null))
    );
  }

  updateMany(ids: string[], field: string, value: unknown): Observable<UpdateManyResult<T>> {
    const body = { ids, field, value, operator: '$mul' };
    return this.http.post<UpdateManyResult<T>>(`${this.baseUrl}/update-many`, body).pipe(delay(1000));
  }

  updateFromData(id: string, data: Dictionary): Observable<T | null> {
    return this.update(id, data as Partial<T>);
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete(`${this.baseUrl}/${id}`).pipe(
      delay(this.DELAY),
      map(() => true),
      catchError(error => this.handleCommandError(error, false))
    );
  }

  sanitizeValues(item: Partial<T>) {
    Object.entries(item).forEach(([key, value]) => {
      if (value === '') {
        set(item, key, null);
      }
    });
  }

  protected buildQuey({ filter, page, sort, projection }: Find): string {
    const params = this.buildFilter(filter);

    if (page?.pageSize) {
      params['$limit'] = page.pageSize;
      if (page.pageIndex) {
        params['$skip'] = page.pageIndex * page.pageSize;
      }
    }

    if (sort?.active && sort?.direction) {
      params['$sort'] = `${sort.active}:${sort.direction}`;
    }

    if (projection?.length) {
      params['$projection'] = projection;
    }

    return new HttpParams({ fromObject: params }).toString();
  }

  protected buildFilter(filter: FindFilter = {}): HttpFilter {
    const params: HttpParamsOptions['fromObject'] = {};

    Object.entries(filter).forEach(([key, value]) => {
      if (value instanceof Date) {
        const gte = DateTime.fromJSDate(value).toISODate();
        const lte = DateTime.fromJSDate(value).plus({ day: 1 }).toISODate();
        params[`${key}$gte`] = `Date(${gte})`;
        params[`${key}$lte`] = `Date(${lte})`;
      } else if (Array.isArray(value) && (isNumber(value[0]) || isNumber(value[1]))) {
        if (value[0]) {
          params[`${key}$gte`] = `Number(${value[0]})`;
        }
        if (value[1]) {
          params[`${key}$lte`] = `Number(${value[1]})`;
        }
      } else if (Array.isArray(value)) {
        if (value.length > 0) {
          params[`${key}$in`] = [...value, ''];
        }
      } else if (isString(value) && value.startsWith('*')) {
        params[`${key}`] = `RegExp(${value.substring(1)})`;
      } else if (isNotEmpty(value)) {
        params[`${key}`] = `${value}`;
      }
    });

    return params;
  }

  protected handleQueryError<E>(error: HttpErrorResponse, value: E) {
    this.flash.show(MESSAGES.QUERY_ERROR);
    if (error.status === 400) {
      return throwError(() => error);
    }
    if (error.status === 404) {
      return of(value);
    }
    this.errorHandler.handleError(error);
    return of(value);
  }

  protected handleCommandError<E>(error: HttpErrorResponse, value: E) {
    this.flash.show(MESSAGES.COMMAND_ERROR);
    if (error.status === 400) {
      return throwError(() => error);
    }
    if (error.status === 404) {
      return of(value);
    }
    this.errorHandler.handleError(error);
    return of(value);
  }
}
