import { ClassType, ObjectType } from '@shared/types';
import { IsInstance, ValidateNested, ValidationOptions } from 'class-validator';

export function IsEntity(entity: ClassType, validationOptions?: ValidationOptions): PropertyDecorator {
  return (target: ObjectType, propertyKey: string | symbol) => {
    propertyKey = propertyKey.toString();

    IsInstance(entity, validationOptions)(target, propertyKey);

    ValidateNested(validationOptions)(target, propertyKey);
  };
}
