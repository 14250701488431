import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponseBase,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { ENVIRONMENT, SharedEnv } from 'shared-ui/di/environment';
import { storageKeys } from 'shared-ui/models/storage';
import { StorageService } from 'shared-ui/providers/storage-service';

@Injectable()
export class BaseInterceptor implements HttpInterceptor {
  private readonly REFRESH_TOKEN_HEADER = 'Refresh-Token';

  constructor(@Inject(ENVIRONMENT) private env: SharedEnv, private storageService: StorageService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authHeaderName = 'authorization';
    const refreshTokenHeaderName = 'refresh_token';
    const baseUrl: string = this.env.apiUrl;
    const authorization = this.storageService.getItem(storageKeys.AUTH);
    const refreshToken = this.storageService.getItem(storageKeys.REFRESH_TOKEN);
    const modifiedRequest = {
      url: `${baseUrl}/${req.url}`,
      setHeaders: {},
    };

    if (authorization) {
      modifiedRequest.setHeaders = {
        [authHeaderName]: `Bearer ${authorization}`,
        [refreshTokenHeaderName]: `Bearer ${refreshToken}`,
      };
    }

    return next.handle(req.clone(modifiedRequest)).pipe(
      tap(event => this.refreshToken(event as HttpResponseBase)),
      retry(2)
    );
  }

  private refreshToken(event: HttpResponseBase) {
    if (event.headers) {
      const refreshToken = event.headers.get(this.REFRESH_TOKEN_HEADER);
      if (refreshToken) {
        this.storageService.setItem(storageKeys.AUTH, refreshToken);
      }
    }
  }
}
