export interface TreeEntity {
  _id: string;
  parentId?: string;
  children?: TreeEntity[];
  path?: string;
  name?: string;
}

export function listToTree<T extends TreeEntity>(list: T[]): T[] {
  const roots: T[] = [];
  const rootItems = list.filter(value => !value.parentId);
  for (const item of rootItems) {
    addChildrenToItem(item, list);
    roots.push(item);
  }
  return roots;
}

export function treeToList<T extends TreeEntity>(tree: T[]): T[] {
  const list: T[] = [];
  tree.forEach(item => pushItemToList(item, list));
  return list;
}

export function getSubtree<T extends TreeEntity>(id: string, tree: T[]): T | undefined {
  for (const root of tree) {
    const found = searchItemChildren(root, id);
    if (found) return found as T;
  }
  return undefined;
}

export function getIdsForItemChildren<T extends TreeEntity>(item: T): string[] {
  const ids: string[] = [];
  pushIdToList(item, ids);
  return ids;
}

function addChildrenToItem(item: TreeEntity, list: TreeEntity[]) {
  item.children = [];
  const children = list.filter(value => {
    if (!value.parentId) return false;
    return item._id === value.parentId;
  });
  for (const child of children) {
    item.children.push(child);
    addChildrenToItem(child, list);
  }
}

function pushItemToList(item: TreeEntity, list: TreeEntity[], path = '') {
  item.path = (path ? path + ' | ' : '') + item.name;
  list.push(item);
  const children = item.children ?? [];
  for (const child of children) {
    pushItemToList(child, list, item.path);
  }
  item.children = [];
}

function searchItemChildren(item: TreeEntity, searchId: string): TreeEntity | undefined {
  if (item._id === searchId) return item;
  const children = item.children ?? [];
  for (const child of children) {
    const equals = searchItemChildren(child, searchId);
    if (equals) return equals;
  }
  return undefined;
}

function pushIdToList(item: TreeEntity, list: string[]) {
  list.push(item._id);
  if (item.children) {
    for (const child of item.children) {
      pushIdToList(child, list);
    }
  }
}
