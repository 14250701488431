<div #origin (click)="isOpen = true" [ngStyle]="style">
  <ng-content select="[trigger]"></ng-content>
</div>

<ng-template
  cdk-connected-overlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
  [cdkConnectedOverlayBackdropClass]="backdropClass"
  [cdkConnectedOverlayWidth]="width"
  (attach)="attached.emit()"
  (detach)="detached.emit()"
  (backdropClick)="isOpen = false">
  <ng-content></ng-content>
</ng-template>
