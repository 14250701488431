import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TRANSLATIONS } from 'shared-ui/providers/locales';
import { Formatter } from './formatter';

type StringMap = Map<string, string>;
export type Params = string[] | Record<string, unknown>;
export type Translations = Record<string, Record<string, string>>;

export interface ITranslator {
  translate(message: string, params?: Params, language?: string): string;
}

@Injectable({
  providedIn: 'root',
})
export class Translator implements ITranslator {
  private _paginatorIntl: MatPaginatorIntl;
  translations: Map<string, StringMap> = new Map();

  constructor(
    protected formatter: Formatter,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(TRANSLATIONS) @Optional() translations: Translations
  ) {
    this.loadMessages(translations);
  }

  translate(message: string, params?: Params, language?: string): string {
    const translation = this.translateMessage(message, language || this.locale);
    return this.formatter.format(translation, params);
  }

  getPaginatorIntl(): MatPaginatorIntl {
    if (this._paginatorIntl) return this._paginatorIntl;
    return (this._paginatorIntl = this.createPaginatorIntl());
  }

  protected loadMessages(translations?: Translations) {
    if (!translations) return;
    Object.keys(translations).forEach(language => {
      const messages: StringMap = new Map(Object.entries(translations[language]));
      this.translations.set(language, messages);
    });
  }

  protected translateMessage(message: string, language: string): string {
    const messages = this.translations.get(language) ?? new Map();
    if (messages.has(message)) {
      return messages.get(message);
    }
    console.warn(`Missing translation for message "${message}"`);
    return message;
  }

  private createPaginatorIntl(): MatPaginatorIntl {
    const original = new MatPaginatorIntl();
    const intl = new MatPaginatorIntl();
    intl.firstPageLabel = this.translate('First page');
    intl.itemsPerPageLabel = this.translate('Items per page:');
    intl.lastPageLabel = this.translate('Last page');
    intl.nextPageLabel = this.translate('Next page');
    intl.previousPageLabel = this.translate('Previous page');
    intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      const label = original.getRangeLabel(page, pageSize, length);
      return label.replace('of', this.translate('of'));
    };
    return intl;
  }
}
