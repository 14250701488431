import { BaseEntity } from '@shared/entity/base-entity';
import { Field } from '@shared/model/field';

export class CategoryParamEntity extends BaseEntity {
  @Field(true, String)
  id!: string;

  @Field(true, String)
  label!: string;

  @Field(true, String)
  type!: string;

  @Field(false, String)
  def?: string;
}
