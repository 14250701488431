import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { InjectionToken } from '@angular/core';
import messagesCs from 'shared-ui/locales/messages.cs-CZ';
import { storageKeys } from 'shared-ui/models/storage';
import { StorageService } from 'shared-ui/providers/storage-service';

registerLocaleData(localeCs, 'cs-CZ');

export const TRANSLATIONS = new InjectionToken('TRANSLATIONS');

export function getLanguage(): string {
  const storageService = new StorageService();
  return storageService.getItem(storageKeys.LOCALE_ID) || 'cs-CZ';
}

export function getTranslations(): Record<string, Record<string, string>> {
  return {
    'cs-CZ': messagesCs,
  };
}
