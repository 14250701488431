import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { ENVIRONMENT, SharedEnv } from 'shared-ui/di/environment';
import { StorageService } from 'shared-ui/providers/storage-service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(@Inject(ENVIRONMENT) private env: SharedEnv, private storageService: StorageService) {
    if (this.env.sentry.enabled) {
      this.initSentry();
    }
  }

  handleError(error: unknown) {
    const extractedError = this.extractError(error) || 'Handled unknown error';
    if (this.env.sentry.enabled) {
      Sentry.setExtra('localStorage', this.getLocalStorageDump());
      Sentry.captureException(extractedError);
    } else {
      console.error(extractedError);
    }
  }

  extractError(error: unknown) {
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof Error) {
        return error.error;
      }

      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      return error.message;
    }

    return null;
  }

  private initSentry() {
    Sentry.init({
      dsn: this.env.sentry.dsn,
      integrations: [
        new Sentry.Integrations.TryCatch({ XMLHttpRequest: false }),
      ],
    });
  }

  private getLocalStorageDump() {
    try {
      return this.storageService.getEntries();
    } catch (error) {
      return [];
    }
  }
}
