import { BaseRootEntity } from '@shared/entity/base-root-entity';
import { Field } from '@shared/model/field';

export class ManufacturerEntity extends BaseRootEntity {
  @Field(true, String)
  name!: string;

  @Field(false, String)
  description?: string;

  @Field(false, String)
  weburl?: string;
}
