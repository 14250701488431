import { Injectable } from '@angular/core';

export interface StorageSchema {
  readonly length: number;
  clear(): void;
  getItem(key: string): string | null;
  key(index: number): string | null;
  removeItem(key: string): void;
  setItem(key: string, value: string): void;
}

@Injectable({ providedIn: 'root' })
export class StorageService implements StorageSchema {
  private items = new Map<string, string>();

  get length(): number {
    if (this.isLocalStorage()) {
      return localStorage.length;
    } else {
      return this.items.size;
    }
  }

  clear(): void {
    if (this.isLocalStorage()) {
      localStorage.clear();
    } else {
      this.items.clear();
    }
  }

  getItem(key: string): string | null {
    let value: string | null;

    if (this.isLocalStorage()) {
      value = localStorage.getItem(key);
    } else {
      value = this.items.get(key) ?? null;
    }

    return value;
  }

  key(index: number): string | null {
    if (this.isLocalStorage()) {
      return localStorage.key(index);
    } else {
      let mapIndex = 0;
      let result: string | null = null;
      this.items.forEach((value, key) => {
        if (mapIndex === index) result = key;
        mapIndex++;
      });
      return result;
    }
  }

  removeItem(key: string): void {
    if (this.isLocalStorage()) {
      localStorage.removeItem(key);
    } else {
      this.items.delete(key);
    }
  }

  setItem(key: string, value: string): void {
    if (this.isLocalStorage()) {
      localStorage.setItem(key, value);
    } else {
      this.items.set(key, value);
    }
  }

  getEntries(): [string, string][] {
    let entries: [string, string][] = [];
    if (this.isLocalStorage()) {
      entries = Object.entries(localStorage);
    } else {
      this.items.forEach((value, key) => entries.push([key, value]));
    }
    return entries;
  }

  isLocalStorage(): boolean {
    try {
      const storage = window['localStorage'];
      const key = '__storage_test__';
      storage.setItem(key, key);
      storage.removeItem(key);
      return true;
    } catch (error) {
      return false;
    }
  }
}
