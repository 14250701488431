import { isString } from 'class-validator';

class Logger {
  debug(message: unknown, context?: string): void {
    console.debug(this.formatMessage(message, context));
  }

  error(message: unknown, trace?: string, context?: string): void {
    if (message instanceof Error) {
      console.log(this.formatMessage(message.message, context));
      message.stack && console.error(message.stack);
    } else {
      console.log(this.formatMessage(message, context));
      trace && console.error(trace);
    }
  }

  exception(error: Error, context?: string): void {
    this.error(error.message, error.stack, context);
  }

  log(message: unknown, context?: string): void {
    console.log(this.formatMessage(message, context));
  }

  verbose(message: unknown, context?: string): void {
    console.info(this.formatMessage(message, context));
  }

  warn(message: unknown, context?: string): void {
    console.warn(this.formatMessage(message, context));
  }

  formatMessage(message: unknown, context = ''): string {
    return Logger.format(message, context);
  }

  static format(message: unknown, context = ''): string {
    const output = isString(message) ? message : JSON.stringify(message);
    const timestamp = new Date().toLocaleString('en-GB');
    const contextString = context ? `[${context}] ` : '';
    return `${timestamp} ${contextString}${output}`;
  }
}

export const logger = new Logger();
