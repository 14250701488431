import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  I18nPluralPipe,
  NgLocaleLocalization,
  PercentPipe,
} from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { forEach } from 'lodash';
import { Params } from 'shared-ui/providers/translator';

type CurrencyDisplay = 'code' | 'symbol' | 'symbol-narrow' | string | boolean;

@Injectable({
  providedIn: 'root',
})
export class Formatter {
  currencyCode = 'CZK';

  constructor(@Inject(LOCALE_ID) protected locale: string) {}

  currency(
    value: number,
    currencyCode?: string,
    display?: CurrencyDisplay,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    return new CurrencyPipe(locale || this.locale).transform(
      value,
      currencyCode || this.currencyCode,
      display,
      digitsInfo,
      locale
    );
  }

  date(value: Date, format?: string, timezone?: string, locale?: string): string | null {
    return new DatePipe(locale || this.locale).transform(value, format, timezone, locale);
  }

  format(message: string, params?: Params): string {
    return !params ? message : this.formatValue(message, params);
  }

  number(value: number, digitsInfo?: string, locale?: string): string | null {
    return new DecimalPipe(locale || this.locale).transform(value, digitsInfo, locale);
  }

  percent(value: number, digitsInfo?: string, locale?: string): string | null {
    return new PercentPipe(locale || this.locale).transform(value, digitsInfo, locale);
  }

  plural(value: number, pluralMap: { [count: string]: string } | string, locale?: string): string {
    if (typeof pluralMap === 'string') {
      const plurals = pluralMap.split('**');
      pluralMap = {};
      for (let i = 0; i < plurals.length; i += 2) {
        pluralMap[plurals[i]] = plurals[i + 1];
      }
    }
    return new I18nPluralPipe(new NgLocaleLocalization(locale || this.locale)).transform(value, pluralMap, locale);
  }

  protected formatValue(message: string, params: Params): string {
    forEach(params, (value: unknown, index: string) => {
      message = message.replaceAll(`[${index}]`, value as string);
    });
    return message;
  }
}
