import { FieldOptions } from '@shared/model/field';
import { logger } from '@shared/logger';
import { ModelError } from '@shared/model/model-error';
import { Dictionary } from '@shared/types';
import { ClassConstructor, instanceToPlain, plainToInstance } from 'class-transformer';
import { validate, validateSync, ValidationError } from 'class-validator';
import { merge, startCase } from 'lodash';

export class Model {
  static create<T extends Model>(this: ClassConstructor<T>, data: Partial<T>, validate = true): T {
    return Model.createModel(this, data, validate);
  }

  static createFromData<T extends Model>(this: ClassConstructor<T>, data: Dictionary, validate = true): T {
    return Model.createModel(this, data, validate);
  }

  static getFields(): string[] {
    return Object.keys(this.getFieldsOptions());
  }

  static getFieldsOptions(): Record<string, FieldOptions> {
    return (this.prototype as any).fields ?? {};
  }

  static getFieldsLabels(): Record<string, string> {
    const labels: Record<string, string> = {};
    this.getFields().forEach(field => (labels[field] = startCase(field)));
    return labels;
  }

  protected static createModel<T extends Model>(modelClass: ClassConstructor<T>, data: Dictionary, validate = true): T {
    const defaultValues = instanceToPlain(new modelClass());
    data = merge(defaultValues, data);
    const model: T = plainToInstance(modelClass, data, { excludeExtraneousValues: true });
    if (validate) {
      model.validateSync();
    }
    return model;
  }

  async validate(throwError = true): Promise<boolean> {
    const errors = await validate(this);
    this.processErrors(errors, throwError);
    return errors.length === 0;
  }

  validateSync(throwError = true): boolean {
    const errors = validateSync(this);
    this.processErrors(errors, throwError);
    return errors.length === 0;
  }

  protected processErrors(errors: ValidationError[], throwError: boolean) {
    if (errors.length) {
      const error = new ModelError(this.constructor.name, errors);
      this.logErrors(error);
      if (throwError) {
        throw error;
      }
    }
  }

  protected logErrors(error: ModelError) {
    logger.debug(`has failed validations ${error.serialize()}`, this.constructor.name);
  }
}
