import { BaseEntity } from '@shared/entity/base-entity';
import { Field } from '@shared/model/field';

export class BaseRootEntity extends BaseEntity {
  @Field(true, String)
  _id!: string;

  @Field(true, Date)
  createdAt = new Date();

  @Field(true, Date)
  updatedAt = new Date();
}
