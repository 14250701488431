import { Pipe, PipeTransform } from '@angular/core';
import { Params, Translator } from 'shared-ui/providers/translator';

@Pipe({
  name: 'translate',
})
export class TranslatorPipe implements PipeTransform {
  constructor(protected translator: Translator) {}

  transform(value: string, params?: Params, language?: string): string {
    return this.translator.translate(value, params, language);
  }
}

@Pipe({
  name: 't',
})
export class TranslatorAliasPipe extends TranslatorPipe implements PipeTransform {}
