/* eslint-disable */
import { MESSAGES } from 'shared-ui/models/messages';

const messagesCs: Record<string, string> = {
  [MESSAGES.NOT_FOUND]: 'Stránka, kterou hledáte, mohla být odstraněna, změněn její název nebo je dočasně nedostupná.',
  [MESSAGES.QUERY_ERROR]: 'Při načítání stránky došlo k chybě. Zkontrolujte nastavení sítě a zkuste to znovu.',
  [MESSAGES.COMMAND_ERROR]: 'Při zpracování vašeho požadavku nastala chyba. Zkontrolujte nastavení sítě a zkuste to znovu.',
  '': '',
  'Minimum value is [0].': 'Minimální hodnota je [0].',
  'Maximum value is [0].': 'Maximální hodnota je [0].',
  'Form field is required.': 'Pole formuláře je povinné.',
  'Value is not valid email.': 'Hodnota není platná e-mailová adresa.',
  'Minimum length is [0].': 'Minimální délka je [0].',
  'Maximum length is [0].': 'Maximální délka je [0].',
  'Value is not valid number.': 'Hodnota není platné číslo.',
  'Edit': 'Upravit',
  'Save': 'Uložit',
  'Send': 'Odeslat',
  'of': 'z',
  'First page': 'První stránka',
  'Items per page:': 'Počet položek na stránku:',
  'Last page': 'Poslední stránka',
  'Next page': 'Další stránka',
  'Previous page': 'Předchozí stránka',
  'Testing Process': 'Testovací provoz',
  'Page Not Found': 'Stránka nenalezena',
  'Make sure the address is correct and the page hasn\'t moved.': 'Ujistěte se, že adresa je správná a stránka nebyla přesunuta.',
  'Price': 'Cena',
  'Brands': 'Značky',
  'Manufacturers': 'Výrobci',
  'Condition': 'Parametry',
  'Sort by': 'Seřadit dle',
  'Default': 'Výchozí',
  'Lowest Price': 'Nejnižší cena',
  'Highest Price': 'Nejvyšší cena',
  'Name A-Z': 'Názvu A-Z',
  'Name Z-A': 'Názvu Z-A',
  'Show': 'Zobrazit',
  'Add to Cart': 'Přidat do košíku',
  'Category': 'Kategorie',
  'Availibility': 'Dostupnost',
  'Description': 'Popis',
  'Parameters': 'Parametry',
  'Accessories': 'Příslušenství',
  'Yes': 'Ano',
  'No': 'Ne',
  'Empty Cart': 'Prázdný košík',
  'Search': 'Hledat',
  'Items': 'Položky',
  'Total': 'Celkem',
  'View Cart': 'Přejít na košík',
  'Your shopping cart is empty.': 'Váš nákupní košík je prázdný.',
  'Product was added to cart.': 'Produkt byl přidán do nákupního košíku.',
  'Product was removed from shopping cart.': 'Produkt byl odebrán z nákupního košíku.',
  'Go to shopping': 'Jít nakupovat',
  'Product': 'Produkt',
  'Name': 'Název',
  'Quantity': 'Množství',
  'Clear All': 'Vyprázdnit',
  'Continue shopping': 'Pokračovat v nákupu',
  'Proceed to Checkout': 'Přejít k objednávce',
  'Shopping Cart': 'Nákupní košík',
  'Grand total': 'Celkem',
  'Delivery': 'Dodání',
  'Payment': 'Platba',
  'Continue': 'Pokračovat',
  'Contact Info': 'Kontaktní údaje',
  'E-mail': 'E-mail',
  'Phone': 'Telefon',
  'First Name': 'Jméno',
  'Last Name': 'Příjmení',
  'Purchase for the company': 'Nákup na firmu',
  'Company ID': 'IČ',
  'VAT no': 'DIČ',
  'Billing Info': 'Fakturační údaje',
  'Street and no.': 'Ulice a č.p.',
  'City': 'Město',
  'Postal Code': 'PSČ',
  'Country': 'Země',
  'Back': 'Zpět',
  'Confirm': 'Potvrdit',
  'Confirm an order': 'Potvrdit objednávku',
  'Delivery & Payment': 'Dodání a Platba',
  'Delivery Info': 'Dodací údaje',
  'Summary': 'Přehled',
  'Order Summary': 'Shrnutí objednávky',
  'You must choose a delivery!': 'Musíte zvolit způsob dodání!',
  'You must choose a payment!': 'Musíte zvolit typ platby!',
  'Confirmation': 'Potvrzení',
  'Company Name': 'Název firmy',
  'Deliver to different address': 'Doručit na jinou adresu',
  'Addressee': 'Adresát',
  'There was an error processing your order. Please try again.': 'Došlo k chybě při zpracování objednávky. Prosím zkuste to znovu.',
  'News': 'Novinky',
  'Recommended': 'Doporučeno',
  'Height': 'Výška',
  'Width': 'Šířka',
  'Length': 'Délka',
  'Weight': 'Váha',
  'You have no items in your shopping cart.': 'Ve vašem nákupním košíku nemáte žádné položky.',
  'Inspection': 'Revize',
  'Inspections': 'Revize',
  'Inspection request': 'Požadavek na revizi',
  'Boiler Type': 'Typ kotle',
  'DateTime': 'Datum a čas',
  'Appointment': 'Termín',
  'Customer': 'Zákazník',
  'Address': 'Adresa',
  'Email': 'Email',
  'Make another request': 'Zadat další požadavek',
  'Thank you for creating your request.': 'Děkujeme Vám za vytvoření požadavku.',
  'You will be contacted as soon as possible to verify and approve the appointment.': 'Co nejdříve budete kontaktováni pro ověření a schválení termínu.',
  'Unknown price': 'Neznámá cena',
  'Unknown availability': 'Neznámá dostupnost',
  'Inform': 'Informovat',
  'Contact': 'Kontaktovat',
  'Close': 'Zavřít',
  'To Cart': 'Do košíku',
  'Edit order': 'Upravit objednávku',
  'Shipping cost': 'Cena dopravy',
  'Form of payment': 'Typ platby',
  'Tracking number': 'Číslo zásilky',
  'Contact of customer': 'Kontakt na zákazníka',
  'Billing address': 'Fakturační adresa',
  'Delivery address': 'Doručovací adresa',
  'Price/Piece': 'Cena/Množství',
  'Qt.': 'Mn.',
  'Total price': 'Celková cena',
  'Amount to be paid': 'Částka k zaplacení',
  'Profile': 'Profil',
  'Users': 'Uživatelé',
  'Roles': 'Role',
  'User Name': 'Uživatelské jméno',
  'Active': 'Aktivní',
  'User Profile': 'Uživatelský profil',
  'Change Email': 'Změnit email',
  'Change Password': 'Změnit heslo',
  'Password Repeat': 'Heslo pro kontrolu',
  'User was created': 'Uživatel byl vytvořen',
  'User was updated': 'Uživatel byl změněn',
  'Disable the purchase option': 'Zakázat možnost nákupu',
  'This email is already used': 'Tento e-mail je již použit',
  'There was error during loading data.': 'Při načítání dat došlo k chybě.',
  'There was an error to process a request.': 'Při zpracování požadavku došlo k chybě.',
  'Sign in with Email address': 'Přihlaste se pomocí e-mailové adresy',
  'Forgot password': 'Zapomenuté heslo',
  'Back to Overview': 'Zpět na přehled',
  'Back to Login': 'Zpět na přihlášení',
  'Reset': 'Reset',
  'Enter your email address below and we will send you password reset instructions.': 'Níže zadejte svou e-mailovou adresu a my vám zašleme pokyny k obnovení hesla.',
  'Email was not found!': 'Email nebyl nalezen!',
  'Email with instructions for password reset was send to you!': 'Byl vám zaslán e-mail s pokyny pro resetování hesla!',
  'Reset your password': 'Obnova hesla',
  'Change password': 'Změnit heslo',
  'Request for reset password has expired!': 'Platnost žádosti na reset hesla vypršela!',
  'New': 'Nový',
  'Cancel': 'Zrušit',
  'Login': 'Přihlášení',
  'Bad email or password!': 'Špatný e-mail nebo heslo!',
  'Password': 'Heslo',
  'Log In': 'Přihlásit se',
  'Log Out': 'Odhlásit se',
  'Passwords do not match': 'Hesla se neshodují',
  'E-mail was changed. You can login using new email.': 'E-mail byl změněn. Přihlásit se můžete pomocí nového emailu.',
  'Password was changed. You can login using new password.': 'Heslo bylo změněno. Můžete se přihlásit pomocí nového hesla.',
  'Label': 'Označení',
  'Type': 'Typ',
  'Definition': 'Definice',
  'Products': 'Produkty',
  'Categories': 'Kategorie',
  'Are you sure you want to delete the item?': 'Opravdu chcete smazat položku?',
  'Add': 'Přidat',
  'Manufacturer': 'Výrobce',
  'URL': 'URL',
  'Title': 'Název',
  'Status': 'Stav',
  'Actions': 'Akce',
  'Edit Product': 'Upravit produkt',
  'Delete Product': 'Smazat produkt',
  'Back to Products': 'Zpět na produkty',
  'Product was deleted.': 'Produkt byl smazán.',
  'Product was not deleted. Try again later.': 'Produkt nebyl smazán. Zkuste to později znovu.',
  'New Product': 'Nový produkt',
  'New product was created.': 'Nový produkt byl vytvořen.',
  'New product was not created. Try again later.': 'Nový produkt nebyl vytvořen. Zkuste to později znovu.',
  'Main Data': 'Hlavní údaje',
  'Price List': 'Ceník',
  'Stock': 'Sklad',
  'Related': 'Související',
  'Delivery Days': 'Doba dodání',
  'Price with VAT': 'Cena s DPH',
  'Collapse All': 'Sbalit vše',
  'Expand All': 'Rozbalit vše',
  'Back to Categories': 'Zpět na kategorie',
  'Delete Category': 'Smazat kategorii',
  'Basic Info': 'Základní údaje',
  'Subcategories': 'Podkategorie',
  'Full Title': 'Celý název',
  'Parent Category': 'Nadřazená kategorie',
  'Keywords': 'Klíčová slova',
  'Position': 'Pozice',
  'New category was created.': 'Nová kategorie byla vytvořena.',
  'Delete selected category?': 'Smazat vybranou kategorii?',
  'Delete': 'Smazat',
  'Category was deleted.': 'Kategorie byla smazána.',
  'Category was not deleted. Try again later.': 'Kategorie nebyla smazána. Zkuste to později znovu.',
  'New Category': 'Nová kategorie',
  'Changes were saved.': 'Změny byly uloženy.',
  'Changes were not saved. Try again later.': 'Změny nebyly uloženy. Zkuste to později znovu.',
  'Category was not found.': 'Kategorie nebyla nalezena.',
  'Edit Manufacturer': 'Upravit výrobce',
  'Delete Manufacturer': 'Smazat výrobce',
  'Delete manufacturer [0]?': 'Smazat výrobce [0]?',
  'Manufacturer was deleted.': 'Výrobce byl smazán.',
  'Manufacturer was not deleted. Try again later.': 'Výrobce nebyl smazán. Zkuste to později znovu.',
  'Back to Manufacturers': 'Zpět na výrobce',
  'New Manufacturer': 'Nový výrobce',
  'New manufacturer was created.': 'Nový výrobce byl vytvořen.',
  'New manufacturer was not created. Try again later.': 'Nový výrobce nebyl vytvořen. Zkuste to později znovu.',
  'Selection': 'Výběr',
  'Checkmark': 'Zaškrtnutí',
  'Range': 'Rozsah',
  'EAN': 'EAN',
  'Image': 'Obrázek',
  'Images': 'Obrázky',
  'Purchase Price': 'Nákupní cena',
  'Standard Price': 'Stadartní cena',
  'Action Price': 'Akční cena',
  'Short Description': 'Krátký popis',
  'Default Image': 'Výchozí obrázek',
  'Choose Image': 'Vybrat obrázek',
  'Upload Image': 'Nahrát obrázek',
  'Image was successfully uploaded to server.': 'Obrázek byl úspěšně nahrán na server.',
  'Choose Images': 'Vybrat obrázky',
  'Upload Images': 'Nahrát obrázky',
  'Images were successfully uploaded to server.': 'Obrázky byly úspěšně nahrány na server.',
  'Min. Price': 'Min. Cena',
  'Max. Price': 'Max. Cena',
  'Image was removed from gallery.': 'Obrázek byl odstraněn z galerie.',
  'List of Accessories': 'Seznam příslušenství',
  'Available products': 'Dostupné produkty',
  'Dimensions': 'Rozměry',
  'Orders': 'Objednávky',
  'Order': 'Objednávka',
  'Code': 'Kód',
  'Date': 'Datum',
  'Time': 'Čas',
  'Customer Name': 'Jméno zákazníka',
  'Products Count': 'Počet produktů',
  'Delivery Method': 'Způsob doručení',
  'Payment Method': 'Typ platby',
  'State': 'Stav',
  'All Orders': 'Všechny objednávky',
  'CREATED': 'VYTVOŘENO',
  'ACCEPTED': 'PŘIJATO',
  'PAID': 'ZAPLACENO',
  'EXPEDED': 'EXPEDOVÁNO',
  'COMPLETED': 'DOKONČENO',
  'CANCELED': 'ZRUŠENO',
  'Created': 'Vytvořeno',
  'Accepted': 'Přijato',
  'Paid': 'Zaplaceno',
  'Expeded': 'Expedováno',
  'Completed': 'Dokončeno',
  'Canceled': 'Zrušeno',
  'Map': 'Mapa',
  'Boilers Inspections': 'Revize kotlů',
  'Back to Inspections': 'Zpět na revize',
  'PENDING': 'ČEKAJÍCÍ',
  'RESOLVED': 'VYŘEŠENO',
  'CLOSED': 'UZAVŘENO',
  'Order was successfully updated.': 'Objednávka byla upravena.',
  'Dashboard': 'Přehled',
  'Settings and params': 'Nastavení a parametry',
  'Editor description': 'Editor popisu',
  'Files': 'Soubory',
  'Choose Files': 'Vybrat soubory',
  'Upload Files': 'Nahrát soubory',
  'Files were successfully uploaded to server.': 'Soubory byly úspěšně nahrány na server.',
  'Free': 'Zdarma',
  'Shipment & Payment': 'Doprava a platba',
  'Finish the order': 'Dokončit objednávku',
  'The name must be different from the original.': 'Název se musí lišit od originálu.',
  'Original Name': 'Původní název',
  'Datetime': 'Termín',
  'Detail': 'Detail',
  'Filter': 'Filtr',
  'Apply': 'Použít',
  'Copy': 'Kopírovat',
  'Duplicate': 'Duplikovat',
  'Including files and images': 'Včetně souborů a obrázků',
  'Download': 'Ke stažení',
  'The weight of the shipment is approx. [0] kg.': 'Hmotnost zásilky je cca [0] kg.',
  'You must agree to the terms.': 'Musíte souhlasit s podmínkami.',
  'I agree to the terms and conditions and acknowledge the processing of personal data.': 'Souhlasím s obchodními podmínkami a beru na vědomí zpracování osobních údajů.',
  'Url': 'Url',
  'Content': 'Obsah',
  'Articles': 'Články',
  'Username': 'Uživatelské jméno',
  'Item was successfully updated': 'Položka byla úspěšně aktualizována.',
  'Manufacturer Name': 'Název výrobce',
  'Category Name': 'Název kategorie',
  'Contacts': 'Kontakty',
  'Opening Hours': 'Otevírací doba',
  'Show all results': 'Zobrazit všechny výsledky',
  'Mass Editing': 'Hromadné úpravy',
  'Field': 'Položka',
  'Change (%)': 'Změna (%)',
  'New Price': 'Nová cena',
  'All items were updated.': 'Všechny položky byly aktualizovány.',
};

export default messagesCs;
