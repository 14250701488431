import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'shared-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent {
  @Input() style: Record<string, any> = { display: 'inline-block' };
  @Input() isOpen = false;
  @Input() hasBackdrop = true;
  @Input() backdropClass = 'cdkb';
  @Input() width: number | string = '';
  @Output() attached = new EventEmitter<void>();
  @Output() detached = new EventEmitter<void>();

  close() {
    this.isOpen = false;
  }

  open() {
    this.isOpen = true;
  }
}
