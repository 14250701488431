import { CategoryEntity } from '@shared/domain/products/category.entity';
import { ManufacturerEntity } from '@shared/domain/products/manufacturer.entity';
import { BaseRootEntity } from '@shared/entity/base-root-entity';
import { EntityField } from '@shared/entity/entity-field';
import { Field } from '@shared/model/field';
import { Dictionary } from '@shared/types';
import { IsObject } from 'class-validator';
import { deburr, kebabCase } from 'lodash';

export class ProductEntity extends BaseRootEntity {
  @Field(false, String)
  ean?: string;

  @Field(false, String)
  productNumber?: string;

  @Field(true, String)
  name!: string;

  @Field(false, String)
  shortDescription?: string;

  @Field(false, String)
  description?: string;

  @Field(false, String)
  url?: string;

  @Field(true, Number)
  price = 0;

  @Field(false, Number)
  purchasePrice?: number;

  @Field(false, Number)
  standardPrice?: number;

  @Field(false, Boolean)
  includingVat = false;

  @Field(false, Number)
  percentVat = 0.21;

  @Field(false, Number)
  actionPrice?: number;

  @Field(false, Date)
  actionFrom?: Date;

  @Field(false, Date)
  actionTo?: Date;

  @Field(true, Number)
  weight = 0;

  @Field(true, Number)
  availability = 3;

  @Field(true, Number)
  stock = 0;

  @Field(false, Boolean)
  freeShipping = false;

  @Field(false, String)
  defaultImage?: string;

  @Field(true, [String])
  images: string[] = [];

  @Field(true, [String])
  files: string[] = [];

  @Field(true, Object, [IsObject()])
  params: Dictionary = {};

  @Field(true, [String])
  accessories: string[] = [];

  @Field(true, Object, [IsObject()])
  dimensions = {
    length: 0,
    width: 0,
    height: 0,
  };

  @Field(true, String)
  categoryId!: string;

  @Field(true, String)
  manufacturerId!: string;

  @Field(false, String)
  categoryName = '';

  @Field(false, String)
  manufacturerName = '';

  @EntityField(false, CategoryEntity)
  category?: CategoryEntity;

  @EntityField(false, ManufacturerEntity)
  manufacturer?: ManufacturerEntity;

  @Field(false, Boolean)
  buyForbidden = false;

  get perimeter(): number {
    return this.dimensions.length * 2 + this.dimensions.width * 2;
  }

  get area(): number {
    return this.dimensions.length * this.dimensions.width;
  }

  get volume(): number {
    return this.dimensions.length * this.dimensions.width * this.dimensions.height;
  }

  get shopUrl(): string {
    return this.url || kebabCase(deburr(this.name));
  }

  get isEanValid(): boolean {
    const pattern = /^([0-9]{8}|[0-9]{13,14})$/;
    return pattern.test((this.ean ?? '').trim());
  }
}
