import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ITranslator, Translator } from 'shared-ui/providers/translator';

@Injectable()
export class FormErrors {
  constructor(private translator: Translator) {}

  static formatError(errors: ValidationErrors | null, translator: ITranslator): string {
    let message: string;
    let messageParams: string[] = [];

    if (!errors) {
      message = '';
    } else if (errors.min) {
      messageParams = [errors.min.min];
      message = 'Minimum value is [0].';
    } else if (errors.max) {
      messageParams = [errors.max.max];
      message = 'Maximum value is [0].';
    } else if (errors.required) {
      message = 'Form field is required.';
    } else if (errors.email) {
      message = 'Value is not valid email.';
    } else if (errors.minLength) {
      messageParams = [errors.minLength.requiredLength];
      message = 'Minimum length is [0].';
    } else if (errors.maxLength) {
      messageParams = [errors.maxLength.requiredLength];
      message = 'Maximum length is [0].';
    } else if (errors.number) {
      message = 'Value is not valid number.';
    } else {
      message = 'Value is bad.';
    }

    return translator.translate(message, messageParams);
  }

  getError(errors: ValidationErrors | null) {
    return FormErrors.formatError(errors, this.translator);
  }
}
