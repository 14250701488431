import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import type * as icons from 'shared-ui/components/icon/icons.json';

export type Icon = keyof typeof icons;

@Component({
  selector: 'icon',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '{{icon}}',
})
export class IconComponent {
  @Input()
  icon?: Icon;

  @HostBinding('class')
  cssClass = 'material-icons notranslate';

  @Input()
  @HostBinding('class.md-inactive')
  inactive = false;

  @Input()
  @HostBinding('class.md-light')
  dark = false;
}
