import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { QuillModule } from 'ngx-quill';
import { AppFormFieldComponent } from 'shared-ui/components/app-form-field.component';
import { DropdownComponent } from 'shared-ui/components/dropdown/dropdown.component';
import { DatagridValuePipe } from 'shared-ui/components/datagrid/datagrid-value.pipe';
import { DatagridComponent } from 'shared-ui/components/datagrid/datagrid.component';
import { DatagridFilterBtnComponent } from 'shared-ui/components/datagrid/filter/filter-btn.component';
import { DatagridFilterComponent } from 'shared-ui/components/datagrid/filter/filter.component';
import { DeleteDialogComponent } from 'shared-ui/components/delete-dialog/delete-dialog.component';
import { AppFormButtonsComponent } from 'shared-ui/components/forms/app-form-buttons.component';
import { FormlyLabelComponent } from 'shared-ui/components/forms/formly-label.component';
import { FormlyRepeatComponent } from 'shared-ui/components/forms/formly-repeat.component';
import { FormlyRichtextComponent } from 'shared-ui/components/forms/formly-richtext.component';
import { IconComponent } from 'shared-ui/components/icon/icon.component';
import { LoaderComponent } from 'shared-ui/components/loader/loader.component';
import { NotFoundComponent } from 'shared-ui/components/not-found/not-found.component';
import { PageLoaderComponent } from 'shared-ui/components/page-loader/page-loader.component';
import { AppFormInputDirective } from 'shared-ui/directives/app-form-input.directive';
import { VisibleDirective } from 'shared-ui/directives/visible.directive';
import { CustomerPricePipe } from 'shared-ui/pipes/customer-price.pipe';
import { FieldErrorPipe } from 'shared-ui/pipes/field-error.pipe';
import { FileUrlPipe } from 'shared-ui/pipes/file-url.pipe';
import { ForeignCurrencyPipe } from 'shared-ui/pipes/foreign-currency.pipe';
import { FormErrorPipe } from 'shared-ui/pipes/form-error.pipe';
import { LocaleCurrencyPipe } from 'shared-ui/pipes/locale-currency.pipe';
import { ProductImageUrlPipe } from 'shared-ui/pipes/product-image-url.pipe';
import { SafeHtmlPipe } from 'shared-ui/pipes/safe-html.pipe';
import { SafeUrlPipe } from 'shared-ui/pipes/safe-url.pipe';
import { TranslatorAliasPipe, TranslatorPipe } from 'shared-ui/pipes/translator.pipe';
import { UrlLinkPipe } from 'shared-ui/pipes/url-link.pipe';
import { AppErrorHandler } from 'shared-ui/providers/app-error-handler';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { FormErrors } from 'shared-ui/providers/form-errors';
import { Formatter } from 'shared-ui/providers/formatter';
import { FormlyBuilder } from 'shared-ui/providers/formly-builder';
import { Translator } from 'shared-ui/providers/translator';

const MATERIAL_MODULES: Type<any>[] = [
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSliderModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTabsModule,
  MatTableModule,
  MatTooltipModule,
  MatTreeModule,
];

@NgModule({
  declarations: [
    IconComponent,
    AppFormButtonsComponent,
    AppFormFieldComponent,
    DeleteDialogComponent,
    LoaderComponent,
    PageLoaderComponent,
    NotFoundComponent,
    AppFormInputDirective,
    CustomerPricePipe,
    FieldErrorPipe,
    FileUrlPipe,
    FormErrorPipe,
    ForeignCurrencyPipe,
    LocaleCurrencyPipe,
    ProductImageUrlPipe,
    TranslatorPipe,
    TranslatorAliasPipe,
    UrlLinkPipe,
    DropdownComponent,
    VisibleDirective,
    DatagridComponent,
    DatagridValuePipe,
    DatagridFilterComponent,
    DatagridFilterBtnComponent,
    FormlyLabelComponent,
    FormlyRichtextComponent,
    FormlyRepeatComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
  ],
  providers: [
    AppErrorHandler,
    FlashMessages,
    Formatter,
    FormErrors,
    FormlyBuilder,
    Translator,
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'outline' } },
  ],
  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    QuillModule,
    ReactiveFormsModule,
    RouterModule,
    OverlayModule,
    FormlyModule,
  ],
  exports: [
    ...MATERIAL_MODULES,
    IconComponent,
    AppFormButtonsComponent,
    AppFormFieldComponent,
    DeleteDialogComponent,
    LoaderComponent,
    PageLoaderComponent,
    NotFoundComponent,
    AppFormInputDirective,
    CustomerPricePipe,
    FieldErrorPipe,
    FileUrlPipe,
    FormErrorPipe,
    ForeignCurrencyPipe,
    LocaleCurrencyPipe,
    ProductImageUrlPipe,
    TranslatorPipe,
    TranslatorAliasPipe,
    UrlLinkPipe,
    QuillModule,
    DropdownComponent,
    VisibleDirective,
    DatagridComponent,
    DatagridValuePipe,
    DatagridFilterComponent,
    DatagridFilterBtnComponent,
    FormlyLabelComponent,
    FormlyRichtextComponent,
    FormlyRepeatComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
  ],
})
export class SharedUiModule {}
