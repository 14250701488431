import { Injectable } from '@angular/core';
import { CategoryParamEntity } from '@shared/domain/products/category-param.entity';
import { CategoryEntity } from '@shared/domain/products/category.entity';
import { listToTree } from '@shared/model/trees';
import { plainToInstance } from 'class-transformer';
import { get, keyBy } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CrudHttpService } from 'shared-ui/providers/crud-http-service';

@Injectable()
export class CategoriesService extends CrudHttpService<CategoryEntity> {
  protected baseUrl = 'categories';
  protected entityClass = CategoryEntity;

  getTree(): Observable<CategoryEntity[]> {
    return this.find().pipe(
      map(value => listToTree(value.items)),
      catchError(err => this.handleQueryError(err, []))
    );
  }

  getCategoryWithParents(id: string): Observable<CategoryEntity | undefined> {
    return this.find().pipe(
      map(value => this.mapCategoryParents(value.items, id)),
      catchError(err => this.handleQueryError(err, undefined))
    );
  }

  getCategoriesWithPaths(): Observable<CategoryEntity[]> {
    return this.http.get<CategoryEntity[]>('categories/path').pipe(
      map(items => plainToInstance(this.entityClass, items)),
      catchError(err => this.handleQueryError(err, []))
    );
  }

  getCategoryParams(id: string): Observable<CategoryParamEntity[]> {
    return this.http.get<CategoryParamEntity[]>(`categories/${id}/params`).pipe(
      map(items => plainToInstance(CategoryParamEntity, items)),
      catchError(err => this.handleQueryError(err, []))
    );
  }

  private mapCategoryParents(value: CategoryEntity[], id: string) {
    const map = keyBy(value, '_id');
    const category = map[id];
    let current: CategoryEntity | undefined = category;
    while (current) {
      current.parent = get(map, current.parentId ?? '');
      current = current.parent;
    }
    return category;
  }
}
