import { InjectionToken, Provider } from '@angular/core';

export const ENVIRONMENT = new InjectionToken('ENVIRONMENT');

export interface SharedEnv extends Record<string, any> {
  production: boolean;
  apiUrl: string;
  filesUrl: string;
  imagesUrl: string;
  sentry: {
    enabled: boolean;
    dsn: string;
  };
}

export function createEnvProvider(env: SharedEnv): Provider {
  return {
    provide: ENVIRONMENT,
    useValue: env,
  };
}
