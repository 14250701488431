import { CategoryParamEntity } from '@shared/domain/products/category-param.entity';
import { BaseRootEntity } from '@shared/entity/base-root-entity';
import { ArrayEntityField, EntityField } from '@shared/entity/entity-field';
import { Field } from '@shared/model/field';
import { TreeEntity } from '@shared/model/trees';

export class CategoryEntity extends BaseRootEntity implements TreeEntity {
  @Field(true, String)
  name!: string;

  @Field(true, String)
  fullName!: string;

  @Field(false, String)
  url?: string;

  @Field(false, String)
  keywords?: string;

  @Field(false, String)
  description?: string;

  @Field(false, Number)
  position?: number;

  @ArrayEntityField(true, CategoryParamEntity)
  productParams: CategoryParamEntity[] = [];

  @Field(false, String)
  path?: string;

  @Field(false, String)
  parentId?: string;

  @EntityField(false, CategoryEntity)
  parent?: CategoryEntity;

  @ArrayEntityField(false, CategoryEntity)
  children?: CategoryEntity[];
}
