import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressService } from 'shared-ui/providers/progress.service';

@Component({
  selector: 'shared-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent implements OnInit, OnDestroy {
  loading = false;

  private $pageLoad: Subscription;

  constructor(private progress: ProgressService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.$pageLoad = this.progress.pageLoad.subscribe(value => this.setLoading(value));
  }

  ngOnDestroy(): void {
    this.$pageLoad.unsubscribe();
  }

  setLoading(value: boolean) {
    this.loading = value;
    this.cd.detectChanges();
  }
}
