import { CurrencyPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localeCurrency',
})
export class LocaleCurrencyPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) protected locale: string) {}

  get currencyPipe(): CurrencyPipe {
    return new CurrencyPipe(this.locale);
  }

  transform(value: number, digitsInfo = '1.0-0'): string | null {
    const locale: string = 'CZK';
    //TODO
    return this.currencyPipe.transform(value, locale, 'symbol-narrow', digitsInfo);
  }
}
