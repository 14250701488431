import { ValidationError } from 'class-validator';

export class ModelError extends Error {
  className: string;
  errors: Record<string, string[]> = {};

  constructor(className: string, errors: ValidationError[]) {
    super(`${className} has failed validations`);
    this.className = className;
    this.errors = this.serializeErrors(errors);
  }

  serialize(): string {
    return JSON.stringify({ [this.className]: this.errors });
  }

  serializeErrors(errors: ValidationError[]): Record<string, string[]> {
    const result: Record<string, string[]> = {};
    errors.forEach(error => this.getPropertyErrors(result, error));
    return result;
  }

  getHttpResponse() {
    return {
      statusCode: 400,
      message: 'Entity Validation Exception',
      error: { [this.className]: this.errors },
    };
  }

  protected getPropertyErrors(result: Record<string, string[]>, error: ValidationError, path = '') {
    if (error.children?.length) {
      error.children.forEach(child => this.getPropertyErrors(result, child, `${error.property}.`));
    } else {
      result[path + error.property] = Object.keys(error.constraints ?? {});
    }
  }
}
